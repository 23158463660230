var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "l-no-logo";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"l-drawer-logo\"><img src=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"baseUrl") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":76}}})) != null ? stack1 : "")
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"imageFile") : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"imageAltText") : stack1), depth0))
    + "\"></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"baseUrl") || (depth0 != null ? lookupProperty(depth0,"baseUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"baseUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":69}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<a href=\"#\" class=\"l-clear l-accordion-heading is-closed l-display-block\"> \n		<h3 class=\"l-drawer-text l-padding-top l-padding-bottom l-float-left "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"imageFile") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":71},"end":{"line":2,"column":119}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"titleText") : stack1), depth0))
    + "</h3>						\n		<p class=\"l-float-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"imageFile") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "				<span class=\"t-font-m t-font-weight-normal t-color-standard-100 l-toggle-list l-display-inline-block l-float-right\" data-label-expand=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\" data-label-collapse=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"open") : stack1), depth0))
    + "\">\n					<span class=\"t-caption-label\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"drawer") : depth0)) != null ? lookupProperty(stack1,"open") : stack1), depth0))
    + " </span>\n					<span class=\"t-caption-icon t-icon-arrow-down\"></span>\n				</span>\n		</p>	\n	</a>";
},"useData":true});