/* Component Dependencies */
var cardArticleTemplate = require('templates/cardArticle.hbs');
var cardArticleMirrorTemplate = require('templates/cardArticleMirror.hbs');
var cardArticle2ATemplate = require('templates/cardArticle2A.hbs');
var cardArticle3ATemplate = require('templates/cardArticle3A.hbs');
var cardArticleTagpTemplate = require('templates/cardArticle-tagp.hbs');
var cardArticleAccentTemplate = require('templates/cardArticleAccent.hbs');
var cardArticleBadgeTemplate = require('templates/cardArticle.hbs');
var AriesComponent = require('libs/aries-component');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var mobileFirstPicture = require('common_libs/mobileFirstPicture');

AriesComponent.create({

  type: 'cardArticle',

  template: {
    'cardArticle': cardArticleTemplate,
    'cardArticleMirror' : cardArticleMirrorTemplate,
    'cardArticle2A':cardArticle2ATemplate,
    'cardArticle3A':cardArticle3ATemplate,
    'cardArticle-tagp': cardArticleTagpTemplate,
    'cardArticleAccent':cardArticleAccentTemplate,
    'cardArticleBadge':cardArticleTemplate
  },
  bindEvents: function() {
    var _self = this;
    setTimeout(function() {
      if(typeof window.picturefill === 'function'){
        window.picturefill({reevaluate:true});
       }
		},100);
    mobileFirstPicture.init(_self); 
  },	 	 
  init: function () {
    this.bindEvents();
  }
});
